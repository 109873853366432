import Container from '../Container/Container';
import styles from './BackLines.module.scss';

function BackLines() {
  return (
    <div className={styles.root}>
      <Container variant={3}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Container>
    </div>
  );
}

export default BackLines;
