export type TEMOffer = {
  em_offer_title: string;
  em_offer_description: string;
  img: string;
};

export const emOffers: TEMOffer[] = [
  {
    em_offer_title: 'Budownictwo ogólne',
    img: '/assets/em-grupa/budownictwo.jpeg',
    em_offer_description: `Nadzór nad ogólnym przebiegiem prac budowlanych, zarządzanie terminami oraz koordynacja działań wszystkich zaangażowanych podmiotów.`,
  },
  {
    em_offer_title: 'Architektura',
    img: '/assets/em-grupa/architektura.jpeg',
    em_offer_description: `Zapewnienie zgodności realizacji z projektem architektonicznym, nadzór nad estetyką i funkcjonalnością budynku.`,
  },
  {
    em_offer_title: 'Konstrukcja',
    img: '/assets/em-grupa/konstrukcje.jpg',
    em_offer_description: `Kontrola wykonywania konstrukcji nośnych budynku, weryfikacja zgodności z projektami konstrukcyjnymi oraz normami technicznymi.`,
  },
  {
    em_offer_title: 'Instalacje',
    img: '/assets/em-grupa/instalacje.jpeg',
    em_offer_description: `Nadzór nad montażem i działaniem instalacji elektrycznych, wodno-kanalizacyjnych, grzewczych, wentylacyjnych, klimatyzacyjnych oraz innych instalacji specjalistycznych.`,
  },
  {
    em_offer_title: 'Inżynieria środowiska',
    img: '/assets/em-grupa/inzynieria_srodowiska.jpeg',
    em_offer_description: `Ocena wpływu budowy na środowisko naturalne, kontrola zgodności z wymogami ochrony środowiska oraz zarządzanie odpadami budowlanymi.`,
  },
  {
    em_offer_title: 'Bezpieczeństwo i higiena pracy',
    img: '/assets/em-grupa/bhp.jpeg',
    em_offer_description: `Zapewnienie przestrzegania przepisów bezpieczeństwa i higieny pracy na placu budowy, identyfikacja i eliminacja potencjalnych zagrożeń dla pracowników.`,
  },
  {
    em_offer_title: 'Logistyka budowlana',
    img: '/assets/em-grupa/logistyka_budowlana.jpeg',
    em_offer_description: `Koordynacja dostaw materiałów budowlanych, zarządzanie magazynowaniem oraz transportem na placu budowy.`,
  },
  {
    img: '/assets/em-grupa/finanse.jpeg',
    em_offer_title: 'Finanse i zarządzanie projektem',
    em_offer_description: `Monitorowanie budżetu inwestycji, kontrola kosztów oraz zarządzanie procesem budowlanym w celu zapewnienia terminowości i efektywności działań.`,
  },
];
