import styles from './OfferMenu.module.scss';
import { TOffer } from '../../../store/offers';
import { NavLink } from 'react-router-dom';

function OfferMenu({ offers = [] }: { offers: TOffer[] }) {
  if (!offers.length) return <></>;
  return (
    <ul className={styles.root}>
      {offers.map((offer: TOffer) => (
        <NavLink
          key={offer.offer_href}
          style={{ width: '100%' }}
          to={`/oferta/${offer.offer_href}`}>
          <li key={offer.offer_href}>{offer.offer_title}</li>
        </NavLink>
      ))}
    </ul>
  );
}

export default OfferMenu;
