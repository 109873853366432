import { Helmet } from 'react-helmet';

function MetaData({
  meta_title,
  meta_description,
}: {
  meta_title?: string;
  meta_description?: string;
}) {
  return (
    <Helmet>
      <meta name={'title'} content={meta_title || 'Marcin Mokry'} />
      <title>{meta_title || 'Marcin Mokry'}</title>
      {meta_description && (
        <meta name={'description'} content={meta_description} />
      )}
    </Helmet>
  );
}

export default MetaData;
