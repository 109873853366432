import { useEffect, useRef, useState } from 'react';
import styles from './BigNumber.module.scss';
import Flex from '../../layout/Flex/Flex';
import { useInView } from 'react-intersection-observer';

export function BigNumber({
  number,
  title,
  time = 2,
  delay = 0.25,
  currency,
  description,
}: {
  description?: string;
  currency?: boolean;
  number: number;
  title: string;
  time?: number;
  delay?: number;
}) {
  const [renderedNumber, setRenderedNumber] = useState<number>(0);
  const timer = useRef<any>();

  const { ref, inView } = useInView({ triggerOnce: true });

  const start = () => {
    setRenderedNumber(0);
    const ms = time * 1000;
    const speed = ms / number;

    return setTimeout(() => {
      const interval = setInterval(() => {
        setRenderedNumber((rendered: number) => {
          if (rendered < number) {
            return ++rendered;
          } else {
            clearInterval(interval);
          }
          return rendered;
        });
      }, speed);
    }, delay * 1000);
  };

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (inView) {
      timer.current = start();
    }
  }, [inView, number]);

  let width = 1;
  number > 9 && (width += 1);
  number > 99 && (width += 1);
  number > 999 && (width += 1);

  return (
    <Flex gap='1rem' align='center'>
      <p
        style={{
          width: `${!currency ? width * 30 : 200}px`,
          textWrap: 'nowrap',
        }}
        ref={ref}
        className={styles.number}>
        {!currency
          ? renderedNumber
          : description
          ? `${renderedNumber}${description}`
          : ''}
      </p>
      <p className={styles.title}>{title}</p>
    </Flex>
  );
}

export default BigNumber;
