import Grid from '../../layout/Grid/Grid';
import BlueBar from '../../features/BlueBar/BlueBar';
import Card from '../../features/CardsBox/Card';
import Poster from '../../features/Poster/Poster';
import Arrows from '../../features/ReviewsBox/Arrows';
import Flex from '../../layout/Flex/Flex';
import useWindowWidth from '../../hooks/useWindowWidth';
import useImageViewer from '../../hooks/useImageViewer';
import ImageViewer from '../../layout/ImageViewer/ImageViewer';
import { useEffect, useState } from 'react';
import { sortByDate } from '../../../utils/sortByDate';
import MetaData from '../../layout/MetaData/MetaData';
import usePageContent from '../../hooks/usePageContent';

export type TProject = {
  project_title: string;
  project_description?: string;
  project_img: string;
  project_images: string[];
  date: string;
};

function PortfolioPage({ projects }: { projects: TProject[] }) {
  const { width } = useWindowWidth();
  const { images, description, show, close } = useImageViewer();
  const [page, setPage] = useState(0);
  const content = usePageContent();

  let portfolioColukmns = 3;
  width < 995 && (portfolioColukmns -= 1);
  width < 768 && (portfolioColukmns -= 1);

  const projectsOnPage = 6;
  const start = projectsOnPage * page;
  const end = start + projectsOnPage;

  const handleBack = () => {
    if (page > 0) {
      setPage((page) => --page);
    }
  };

  const handleNext = () => {
    if (end < projects.length) {
      setPage((page) => ++page);
    }
  };

  useEffect(() => {
    document.querySelector('.start')?.scrollIntoView({ behavior: 'smooth' });
  }, [page]);

  return (
    <>
      <MetaData {...content} />
      <div>
        <div className={'start'}></div>
        <BlueBar
          header='REALIZACJE'
          subtitle='Zobacz ostatnio wykonane przez nas projekty.'
          backgroundImg='/assets/portfolio/background.webp'
        />
        <ImageViewer close={close} images={images} description={description} />
        <Poster
          variant={3}
          backgroundImg='/assets/home/background_2.jpg'
          column
          backgroundHeight='70%'>
          <Grid
            style={{ margin: width < 995 ? '3rem 0' : '1rem 0' }}
            cols={portfolioColukmns}
            gap='3rem'>
            {projects && projects.length
              ? [...projects]
                  .sort(sortByDate)
                  .slice(start, end)
                  .map((project, index) => (
                    <Card
                      key={project.project_title + index}
                      description={project.project_description}
                      variant={3}
                      header={project.project_title}
                      img={project.project_img || ''}
                      onClick={() =>
                        show(
                          project.project_images,
                          project.project_description
                        )
                      }
                    />
                  ))
              : ''}
          </Grid>

          <Flex
            justify='center'
            align='center'
            style={{ marginBottom: '6rem' }}>
            <Arrows page={page + 1} onBack={handleBack} onNext={handleNext} />
          </Flex>
        </Poster>
      </div>
    </>
  );
}

export default PortfolioPage;
