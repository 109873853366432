import styles from './Flex.module.scss';

type TFlex = {
  align?: string;
  justify?: string;
  gap?: string;
  column?: boolean;
  style?: any;
  variant?: number;
};

function Flex({
  children,
  align,
  justify,
  gap,
  column,
  style = {},
  variant = 1,
}: TFlex & { children: any }) {
  const localStyle: any = { ...style, display: 'flex' };
  align && (localStyle.alignItems = align);
  justify && (localStyle.justifyContent = justify);
  gap && (localStyle.gap = gap);
  column && (localStyle.flexDirection = 'column');

  return (
    <div
      className={`${styles.root} ${styles[`variant${variant}`]}`}
      style={localStyle}>
      {children}
    </div>
  );
}

export default Flex;
