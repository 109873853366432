import styles from "./Copy.module.scss";
import { useInView } from "react-intersection-observer";

function Copy({ children, style = {} }: { style?: any; children: any }) {
  const { inView, ref } = useInView({ triggerOnce: true });

  return (
    <div
      ref={ref}
      style={style}
      className={`${styles.root} ${inView ? styles.inView : ""}`}>
      {children}
    </div>
  );
}

export default Copy;
