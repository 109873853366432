import axios from 'axios';
import { nanoid } from 'nanoid';

let url = '';

if (process.env.NODE_ENV === 'development') {
  url = 'marcinmokry.pl';
} else if (process.env.NODE_ENV === 'production') {
  url = window.location.host;
}

const apiURL = `https://${url}/wp-json/`;
const contactURL = 'contact-form-7/v1/contact-forms/12/feedback';

const getPageUrl = (page: string) => {
  return apiURL + `wp/v2/pages?slug=${page}&_fields=acf`;
};

const getModelUrl = (model: string) => {
  return apiURL + `wp/v2/${model}?_fields=acf,date&per_page=100`;
};

export const getPageACF = (page: string) =>
  api.get(getPageUrl(page)).then(({ data }) => data[0]?.acf);

export const getModelACF = (model: string) =>
  api
    .get(getModelUrl(model))
    .then(({ data }) => data.map((item: any) => item.acf));

export const getModelACFWithDate = (model: string) =>
  api
    .get(getModelUrl(model))
    .then(({ data }) =>
      data.map((item: any) => ({ ...item.acf, date: item.date }))
    );

export const api = axios.create({
  baseURL: apiURL,
});

export const API = {
  sendMessage({
    name,
    email,
    message,
  }: {
    name: string;
    email: string;
    message: string;
  }) {
    const form = new FormData();
    form.append('name-surname', name);
    form.append('email', email);
    form.append('message', message);
    form.append('_wpcf7_unit_tag', `wpcf7-fdb83534-${nanoid(5)}`);
    return api.post(apiURL + contactURL, form);
  },

  getContact() {
    return getPageACF('dane-kontaktowe');
  },

  getProjects() {
    return getModelACFWithDate('projects');
  },

  getOffers() {
    return getModelACF('offers');
  },

  getEMOffers() {
    return getModelACF('offers_em_grupa');
  },

  getReviews() {
    return getModelACFWithDate('review');
  },

  getMarqueeEm() {
    return getModelACF('markiza-e');
  },
};
