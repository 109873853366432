import './noBullets.scss';
import styles from './ImageViewer.module.scss';
import { createPortal } from 'react-dom';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function ImageViewer({
  images,
  description,
  close = () => {},
}: {
  images: any[];
  description?: string;
  close?: Function;
}) {
  const root = document.body.querySelector('#root');

  if (!root || !images.length) return <></>;

  return createPortal(
    <div className={styles.root}>
      <button onClick={() => close()} className={styles.close}>
        X
      </button>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}>
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className={styles.slide}>
              <img alt={'Realizacja'} src={image} loading='lazy' />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {description && <p>{description}</p>}
    </div>,
    root
  );
}

export default ImageViewer;
