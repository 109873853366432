export const banners = [
  {
    href: '/',
    img: '/assets/home/baner.webp',
    headline: `Pełnobranżowy nadzór inwestycji 
budowlanych w przemyśle`,
    description:
      'Zapewniam bezpieczeństwo Twojej inwestycji, eliminując błędy w sztuce budowlanej, które mogą przynieść konsekwencje finansowe oraz zagrozić jakości Twojej budowy.',
    handwrite: 'Marcin Mokry',
  },
  {
    href: '/em-grupa',
    img: '/assets/em-grupa/banner.jpeg',
    headline:
      'Zapewnienie wsparcia technicznego investorom, deweloperom oraz generalnym wykonawcom',
    description:
      'Naszą ambicją jest nie tylko zaspokojnie potrzeb rynku, ale także promocja i rozwój innowacyjnych rozwiązań  wpływających bezpośrednio na cały proces inwestycyjny.',
    logo: '/assets/common/em-grupa.svg',
  },
];
