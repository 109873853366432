import { useInView } from 'react-intersection-observer';
import Container from '../../layout/Container/Container';
import styles from './Poster.module.scss';

export type TPoster = {
  backgroundImg?: string;
  backgroundHeight?: string;
  style?: any;
  column?: boolean;
  variant?: number;
  maskWhite?: boolean;
};

function Poster({
  backgroundImg,
  backgroundHeight,
  children,
  column,
  maskWhite,
  style = {},
  variant = 1,
}: TPoster & { children: any }) {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <section
      style={{ ...style }}
      ref={ref}
      className={`${styles.root} ${column ? styles.column : ''} ${
        styles[`variant${variant}`]
      }  ${inView ? styles.inView : ''}`}>
      {maskWhite && (
        <div className={styles.whiteBack}>
          <Container>
            <div></div>
          </Container>
        </div>
      )}
      <div
        style={backgroundHeight ? { height: backgroundHeight } : {}}
        className={styles.back}>
        {!!backgroundImg && (
          <img
            alt={'Tło'}
            decoding='async'
            loading='lazy'
            className={styles.background}
            src={backgroundImg}
          />
        )}
      </div>
      <Container>
        <div className={styles.wrapper}>{children}</div>
      </Container>
    </section>
  );
}

export default Poster;
