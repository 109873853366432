import { useEffect, useState } from 'react';
import Container from '../../layout/Container/Container';
import FeatureItem from './FeatureItem';
import styles from './FeatureStrip.module.scss';

export type TFeature = {
  title: string;
  icon?: string;
  variant?: number;
};

function FeatureStrip({ features = [] }: { features: TFeature[] }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 1000);
  }, []);

  return (
    <Container>
      <section className={`${styles.root} ${animate ? styles.animate : ''}`}>
        <div className={styles.strip}>
          {features.map((feature, index) => (
            <FeatureItem variant={2} key={index} {...feature} />
          ))}
        </div>
        <div className={styles.strip}>
          {features.map((feature, index) => (
            <FeatureItem variant={2} key={index} {...feature} />
          ))}
        </div>
      </section>
    </Container>
  );
}

export default FeatureStrip;
