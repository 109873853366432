import { TReview } from '../../../store/reviews';
import Card from '../CardsBox/Card';
import styles from './ReviewsWrapper.module.scss';
import { useInView } from 'react-intersection-observer';
import { sortByDate } from '../../../utils/sortByDate';

function ReviewsWrapper({
  reviews,
  selectReview,
}: {
  selectReview: Function;
  reviews: TReview[];
}) {
  const { inView, ref } = useInView({ triggerOnce: true, threshold: 0.88 });

  const getReview = (review: TReview) => {
    return {
      header: review.title,
      description: review.description,
      img: review.img,
      author: review.footer,
    };
  };

  return (
    <div
      ref={ref}
      className={`${styles.root} ${
        inView ? styles.inView : ''
      } reviews-wrapper`}>
      {[...reviews].sort(sortByDate).map((review) => (
        <Card
          onClick={() => selectReview(review)}
          key={review.date}
          variant={2}
          {...getReview(review)}
        />
      ))}
    </div>
  );
}

export default ReviewsWrapper;
