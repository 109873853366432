export type TOffer = {
  offer_title: string;
  offer_href: string;
  offer_img: string;
  offer_icon?: string;
  offer_subtitle?: string;
  offer_description?: string;
  offer_list?: string;
};

export const offers: TOffer[] = [
  {
    offer_title: 'Organizacja projektu / koordynacja procesu projektowania',
    offer_href: 'organizacja-projektu',
    offer_img: '/assets/offer/organizacja.jpeg',
    offer_icon: '/assets/home/organizacja.svg',
    offer_subtitle: `Służę pełnym wsparciem dla inwestorów na etapie organizacji projektu. Zakres oferowanych usług obejmuje m.in.:`,
    offer_list: `*wsparcie przy wyborze biura projektowego
      *opiniowanie projektu koncepcyjnego
      *koordynacje działań pomiędzy poszczególnymi projektantami w celu uzyskania kompletnej dokumentacji projektowej
      *sprawdzenie dokumentacji projektowej wraz z niezbędnymi uzgodnieniami i pozwoleniami
      *sprawdzenie i ocena poprawności wykonania dokumentacji
      *optymalizacja rozwiązań projektowych, uzyskiwanie odpowiednich pozwoleń, uzgodnień, warunków technicznych itp.`,
  },
  {
    offer_title: 'Nadzór inwestorski',
    offer_href: 'nadzor-inwestorski',
    offer_img: '/assets/offer/nadzor.jpeg',
    offer_icon: '/assets/home/nadzor.svg',

    offer_subtitle: `W ramach realizacji usługi sprawowania obowiązków inspektora nadzoru prowadzę bieżący nadzór budowlany nad realizacją inwestycji obejmujący m.in.:`,
    offer_list: `*Reprezentowanie Zamawiającego na budowie przez sprawowanie kontroli zgodności jej realizacji z projektem i pozwoleniem na budowę, przepisami oraz zasadami wiedzy technicznej zgodnie z przepisami Prawa Budowlanego,
      *Kontrolą jakości wykonywanych robót i wbudowanych wyrobów budowlanych,
      *Sprawdzanie i odbiór robót budowlanych,
      *sprawdzenie dokumentacji projektowej wraz z niezbędnymi uzgodnieniami i pozwoleniami,
      *Sprawdzenie i ocena poprawności wykonania dokumentacji,
      *Uczestniczenie w próbach i odbiorach technicznych instalacji`,
  },
  {
    offer_title:
      'Doradztwo techniczne dla banków i instytucji finansujących inwestycje',
    offer_href: 'doradztwo-techniczne-inwestycje',
    offer_img: '/assets/offer/doradztwo-banki.jpeg',
    offer_icon: '/assets/home/doradztwo.svg',
    offer_subtitle: `Działania te polegają na prowadzeniu pełnego monitoringu procesu realizowanej inwestycji, oceny postępów prowadzonych działań, weryfikacji aktualnego stanu inwestycji pod względem harmonogramu i budżetu.`,
  },
  {
    offer_title: 'Audyty techniczne budynków',
    offer_href: 'audyty-techniczne',
    offer_img: '/assets/offer/audyty.jpeg',
    offer_description: `Audyty techniczne polegają na dokładnej ocenie i analizie różnych aspektów technicznych realizowanego projektu budowlanego. Ich celem jest identyfikacja ewentualnych problemów, błędów czy niedociągnięć oraz zapewnienie zgodności z wymaganiami technicznymi oraz normami budowlanymi.

Podczas audytów technicznych inspektor nadzoru może przeprowadzać szczegółowe inspekcje stanu zaawansowania prac budowlanych, ocenę jakości wykonania poszczególnych elementów budowli oraz sprawdzanie zgodności z projektem budowlanym. Dodatkowo, audyty techniczne mogą obejmować ocenę wykorzystywanych materiałów budowlanych, kontrolę zgodności z przepisami dotyczącymi bezpieczeństwa oraz identyfikację potencjalnych ryzyk związanych 
z dalszym przebiegiem budowy.

Inspektor nadzoru budowlanego, poprzez przeprowadzanie audytów technicznych, ma za zadanie zapewnić wysoką jakość oraz bezpieczeństwo realizowanego projektu budowlanego. Audyty 
te pozwalają na wczesne wykrycie ewentualnych problemów lub niezgodności, co umożliwia ich szybką korektę oraz minimalizuje ryzyko poważniejszych konsekwencji w przyszłości. Dzięki audytom technicznym, inspektor nadzoru może aktywnie wspierać inwestorów 
w osiągnięciu celów projektu oraz zapewnić zgodność z wymaganiami technicznymi i jakościowymi.`,
  },
  {
    offer_title: 'Inwestor zastępczy',
    offer_href: 'inwestor-zastepczy',
    offer_img: '/assets/offer/inwestor.webp',
    offer_icon: '/assets/home/inwestor.svg',

    offer_subtitle: `W roli Inwestora Zastępczego odpowiadam za zarządzanie inwestycją w fazie przygotowania, jak i w fazie realizacji inwestycji. Całość powierzonych zadań ma charakter kompleksowy 
i wielobranżowy obejmujący m.in.:`,
    offer_list: `*Wybór Zespołu Projektowego, Konsultantów i Wykonawcy, Kontrolę jakości prac projektowych
      *Monitorowanie procesu przygotowywania dokumentacji projektowej zgodnie z wytycznymi Zamawiającego
      *Organizacje i prowadzenie przetargów
      *Stworzenie programu realizacji całej inwestycji
      *Koordynację prowadzonych robót budowlanych
      *Kontrola terminów realizacji, jak też kontrola kosztów
      *Reprezentowania inwestora w kontaktach z uczestnikami procesu inwestycyjnego
      *Przygotowania i przeprowadzania procedur odbioru końcowego uzyskania pozwolenia na użytkowanie obiektu`,
  },
  {
    offer_title: 'Opinie techniczne',
    offer_href: 'opinie-techniczne',
    offer_img: '/assets/offer/opinie.jpg',
    offer_description: `Opinie techniczne wydawane przez inspektora nadzoru mogą dotyczyć różnorodnych aspektów konstrukcyjnych, technicznych i wykonawczych. Mogą one obejmować ocenę materiałów budowlanych, sprawdzenie zgodności z projektem architektonicznym oraz strukturalnym, kontrolę jakości wykonywanych prac oraz ocenę zgodności z wymogami bezpieczeństwa budowlanego.

Inspektor nadzoru budowlanego wydaje opinie techniczne również 
w przypadku napotkania problemów lub niezgodności podczas realizacji inwestycji. W takich sytuacjach może formułować zalecenia dotyczące poprawek lub modyfikacji, aby zapewnić zgodność 
z wymaganiami technicznymi oraz bezpieczeństwem budowy.

W rezultacie, inspektor nadzoru budowlanego poprzez wydawanie opinii technicznych zapewnia jakość, bezpieczeństwo i zgodność wszystkich etapów procesu budowlanego, przyczyniając się do powodzenia projektu oraz zadowolenia inwestorów i użytkowników końcowych.`,
  },
  {
    offer_title: 'Doradztwo techniczne inwestycji',
    offer_href: 'doradztwo-techniczne',
    offer_img: '/assets/offer/doradztwo.png',
    offer_description: `Inspektor nadzoru budowlanego pełni również rolę doradczą w zakresie technicznym podczas procesu budowy. Moim zadaniem jest udzielanie fachowych porad i wskazówek dotyczących różnych aspektów konstrukcyjnych, technicznych i wykonawczych. Doradztwo techniczne inspektora nadzoru może obejmować sugestie dotyczące optymalizacji procesów budowlanych, wyboru odpowiednich materiałów budowlanych, czy też rozwiązywania problemów technicznych napotkanych podczas realizacji projektu.

Inspektor nadzoru budowlanego może doradzać inwestorom 
w kwestiach związanych z wyborem najlepszych rozwiązań technicznych, które zapewnią bezpieczeństwo, trwałość oraz efektywność wykonanych prac. Jego wsparcie obejmuje również udzielanie porad w zakresie zgodności wykonywanych prac z obowiązującymi przepisami budowlanymi oraz normami technicznymi.

Doradztwo techniczne inspektora nadzoru budowlanego ma na celu wspieranie inwestorów w podejmowaniu świadomych decyzji technicznych oraz minimalizację ryzyka wystąpienia problemów czy błędów podczas realizacji inwestycji budowlanych. Dzięki jego wiedzy 
i doświadczeniu, inwestorzy mogą podejmować bardziej świadome decyzje techniczne, które przyczynią się do sukcesu projektu budowlanego.`,
  },
];
