export const contact = {
  name: "MARCIN MOKRY",
  description: "Inspektor nadzoru, konstruktor budowlany w branży przemysłowej",
  phone: "+48 664 932 739",
  email: "marcin.mokry@em-grupa.pl",
  company: "EM Grupa Sp. z o.o.",
  address: "ul. Wałowa 37, 33-100 Tychy",
  nip: "6462981277",
  krs: "0000821014",
  fb: "https://www.facebook.com/profile.php?id=61560236762240",
  li: "https://www.linkedin.com/in/marcin-mokry-5b0abb30b",
};
