import Flex from '../../layout/Flex/Flex';
import BlueBar from '../../features/BlueBar/BlueBar';
import Feature from '../../features/Features/Feature';
import Container from '../../layout/Container/Container';
import ContactForm from '../../features/ContactForm/ContactForm';
import useWindowWidth from '../../hooks/useWindowWidth';
import { contact } from '../../../store/contact';
import MetaData from '../../layout/MetaData/MetaData';
import usePageContent from '../../hooks/usePageContent';

function ContactPage() {
  const { width } = useWindowWidth();
  const column = width < 995;
  const content = usePageContent();

  return (
    <>
      <MetaData {...content} />
      <div>
        <BlueBar
          header='SKONTAKTUJ SIĘ Z NAMI'
          backgroundImg='/assets/contact/background.webp'
        />
        <Container>
          <Flex
            style={{ marginTop: column ? '1rem' : '0' }}
            column={column}
            gap='2rem'
            justify='center'
            align='center'>
            <Feature
              img='/assets/common/phone.svg'
              variant={2}
              title='NUMER TELEFONU'
              subtitle={contact.phone}
            />
            <Feature
              img='/assets/common/e-mail.svg'
              variant={2}
              title='EMAIL'
              subtitle={contact.email}
            />
            <Feature
              img='/assets/common/address.svg'
              variant={2}
              title='ADRES'
              subtitle={contact.address}
            />
          </Flex>
        </Container>
        <Container>
          <Flex variant={2}>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17705.996801505862!2d18.96588891300862!3d50.14086205819269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716c84b558f7e95%3A0xa8dcc17ca4b9f145!2sWa%C5%82owa%2037%2C%2043-100%20Tychy!5e0!3m2!1spl!2spl!4v1718257504112!5m2!1spl!2spl'
              width='600'
              height='500'
              style={{ border: '0' }}
              loading='lazy'></iframe>
            <ContactForm />
          </Flex>
        </Container>
      </div>
    </>
  );
}

export default ContactPage;
