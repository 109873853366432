import { NavLink } from 'react-router-dom';
import styles from './More.module.scss';

function More({ children, to = '/' }: { children: any; to?: string }) {
  return (
    <NavLink
      style={{
        textDecoration: 'none',
        display: 'inline-block',
        alignSelf: 'start',
      }}
      to={to}>
      <div className={styles.root}>
        <p>{children}</p>
        <img alt='Strzałka' loading='lazy' src='/assets/common/arrow.svg'></img>
      </div>
    </NavLink>
  );
}

export default More;
