import { useLocation } from 'react-router-dom';
import { banners } from '../../store/banners';

function useBanner() {
  const { pathname } = useLocation();
  const banner = banners.find((b) => b.href === pathname);
  return { banner };
}

export default useBanner;
