import { NavLink, useLocation } from 'react-router-dom';
import { hrefs } from '../Navbar/hrefs';
import styles from './Sidebar.module.scss';
import { TOffer } from '../../../store/offers';
import Flex from '../Flex/Flex';
import { Fragment } from 'react/jsx-runtime';
import { contact } from '../../../store/contact';

export function Sidebar({
  show,
  offers = [],
}: {
  show: boolean;
  offers: TOffer[];
}) {
  const { pathname } = useLocation();

  const isSelected = (href: string) => {
    if (href.includes('oferta')) return pathname.includes('oferta');
    return pathname === href;
  };

  const getOfferBox = () => (
    <div className={styles.offerBox}>
      {offers.map((offer) => (
        <NavLink key={offer.offer_href} to={'/oferta/' + offer.offer_href}>
          <p style={{ padding: '0.5rem 0' }}>{offer.offer_title}</p>
        </NavLink>
      ))}
    </div>
  );

  return (
    <div className={`${styles.root} ${show ? styles.show : ''}`}>
      <ul>
        <NavLink to={'/'}>
          <li className={`${isSelected('/') ? styles.selected : ''}`}>
            Strona główna
          </li>
        </NavLink>
        {hrefs.map((href) => (
          <Fragment key={href.href}>
            <NavLink to={href.href}>
              <li
                key={href.href}
                className={`${href.isDropdown ? styles.hoverable : ''} ${
                  isSelected(href.href) ? styles.selected : ''
                }`}>
                {href.title}
              </li>
            </NavLink>
            {href.href.includes('oferta') && offers.length ? getOfferBox() : ''}
          </Fragment>
        ))}
      </ul>
      <NavLink to={`tel:${contact.phone}`}>
        <Flex
          justify='center'
          gap='1rem'
          style={{ padding: '1.5rem', backgroundColor: 'white' }}>
          <img
            alt='Telefon'
            src='/assets/common/phone.svg'
            style={{ height: '25px' }}
          />
          <p style={{ fontWeight: '500' }}>{contact.phone}</p>
        </Flex>
      </NavLink>
    </div>
  );
}
