import styles from './ReviewsViewer.module.scss';
import { createPortal } from 'react-dom';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { TReview } from '../../../store/reviews';

function ReviewsViewer({
  reviews,
  currentReview,
  close = () => {},
}: {
  currentReview?: TReview | null;
  reviews: TReview[];
  close?: Function;
}) {
  const root = document.body.querySelector('#root');
  if (!root || !currentReview) return <></>;

  return createPortal(
    <div className={styles.root}>
      <button onClick={() => close()} className={styles.close}>
        X
      </button>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        initialSlide={reviews.indexOf(currentReview)}
        navigation
        pagination={{ clickable: true }}>
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <div className={styles.slide}>
              <div className={styles.review}>
                <img alt={'Realizacja'} src={review.img} loading='lazy' />
                <div className={styles.copy}>
                  <h3>{review.title}</h3>
                  <p>{review.description}</p>
                  <p className={styles.footer}>{review.footer}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>,
    root
  );
}

export default ReviewsViewer;
