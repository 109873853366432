import { useEffect, useState } from 'react';
import { API } from '../../API/api';
import { contact } from '../../store/contact';
import { banners } from '../../store/banners';
import { projects } from '../../store/projects';
import { offers } from '../../store/offers';
import { emOffers } from '../../store/emOffers';
import { reviews } from '../../store/reviews';
import { marqueeEm } from '../../store/marqueeEm';

const data: any = {
  contact,
  banners,
  offers,
  projects,
  emOffers,
  reviews,
  marqueeEm,
};

const sources: { [key: string]: Function } = {
  contact: API.getContact,
  projects: API.getProjects,
  offers: API.getOffers,
  emOffers: API.getEMOffers,
  reviews: API.getReviews,
  marqueeEm: API.getMarqueeEm,
};

function useContent() {
  const [content, setContent] = useState<{ [key: string]: any }>({});

  const getContent = async (name: string) => {
    if (content[name]) {
      return content[name];
    } else {
      return await sources[name]()
        .then((data: any) =>
          setContent((content) => ({ ...content, [name]: data }))
        )
        .catch(() => {
          setContent((content) => ({ ...content, [name]: data[name] }));
        });
    }
  };

  const initContent = async () => {
    await getContent('projects');
    await getContent('contact');
    await getContent('offers');
    await getContent('emOffers');
    await getContent('reviews');
    await getContent('marqueeEm');
  };

  useEffect(() => {
    initContent();
  }, []);

  return { content, getContent };
}

export default useContent;
