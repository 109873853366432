import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getPageACF } from '../../API/api';

export function usePageContent() {
  const [content, setContent] = useState<{ [key: string]: any }>({});
  const { pathname } = useLocation();

  const fetchPage = async () => {
    let currentPath = pathname.includes('/')
      ? pathname.split('/')[1]
      : pathname;
    const data = await getPageACF(currentPath || 'strona-glowna');
    setContent(data);
  };

  useEffect(() => {
    if (content && !Object.keys(content).length) {
      fetchPage();
    }
  }, [pathname]);

  return content && Object.keys(content).length ? content : null;
}

export default usePageContent;
