import Copy from '../../layout/Copy/Copy';
import Poster from '../Poster/Poster';
import Flex from '../../layout/Flex/Flex';
import Arrows from './Arrows';
import Container from '../../layout/Container/Container';
import ReviewsWrapper from './ReviewsWrapper';
import { useState, useEffect } from 'react';
import useWindowWidth from '../../hooks/useWindowWidth';
import { TReview } from '../../../store/reviews';

export function ReviewsBox({
  reviews,
  header,
  setCurrentReview,
}: {
  setCurrentReview: Function;
  header?: string;
  reviews: TReview[];
}) {
  const [page, setPage] = useState(0);
  const { width } = useWindowWidth();
  const small = width < 995;

  const count = width > 768 ? 2 : 1;

  const start = page * count;
  const end = start + count;

  useEffect(() => {
    if (page * count >= reviews.length && page !== 0) {
      setPage(0);
    }
  }, [page]);

  useEffect(() => {
    setPage(0);
  }, [width]);

  return (
    <>
      <Poster backgroundHeight='0px'>
        <Copy>
          <h4>OPINIE INWESTORÓW</h4>
          <h3 style={{ marginBottom: small ? '0' : '' }}>
            {header || 'Lorem ipsum dolor sit amet consectetur'}
          </h3>
        </Copy>
        <Flex align='end' justify='end'>
          <div style={{ margin: '2rem 0' }}>
            <Arrows
              onBack={() => setPage((page) => (page > 0 ? --page : 0))}
              onNext={() => setPage((page) => ++page)}
            />
          </div>
        </Flex>
      </Poster>
      <Container>
        <ReviewsWrapper
          selectReview={setCurrentReview}
          reviews={reviews.slice(start, end)}
        />
      </Container>
    </>
  );
}

export default ReviewsBox;
