import styles from './FeatureItem.module.scss';
import { TFeature } from './FeatureStrip';

function FeatureItem({ icon, title, variant = 1 }: TFeature) {
  return (
    <li className={`${styles.root} ${styles[`variant${variant}`]}`}>
      {icon && <img alt={title} src={icon} />}
      <p>{title}</p>
    </li>
  );
}

export default FeatureItem;
