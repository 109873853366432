import { TOffer } from '../../../store/offers';
import Container from '../Container/Container';
import styles from './Navbar.module.scss';
import OfferMenu from './OfferMenu';
import { hrefs } from './hrefs';
import { NavLink } from 'react-router-dom';

export function Navbar({
  setSidebar,
  sidebar,
  contact,
  offers,
}: {
  contact: any;
  setSidebar: Function;
  sidebar: boolean;
  offers: TOffer[];
}) {
  if (!contact) return <></>;
  return (
    <nav className={styles.root}>
      <Container variant={2}>
        <div className={styles.contents}>
          <NavLink
            key={'/'}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            to='/'>
            <img
              alt='Logo Marcin Mokry'
              className={styles.mainImg}
              loading='lazy'
              src='/assets/navbar/logo_Marcin Mokry.svg'
            />
          </NavLink>
          <ul className={styles.links}>
            {hrefs.map((href) => (
              <li
                key={href.href}
                className={`${href.isDropdown ? styles.hoverable : ''}`}>
                <NavLink to={href.href}>{href.title}</NavLink>
                {href.isDropdown && (
                  <div className={styles.dropdown}>
                    <OfferMenu offers={offers} />
                  </div>
                )}
              </li>
            ))}
            <li>
              <img
                alt={'Kontakt'}
                loading='lazy'
                src='/assets/navbar/Kontakt.svg'
              />
              <p>{contact.phone}</p>
            </li>
          </ul>
          <div className={styles.hamburger}>
            <button
              onClick={() => setSidebar((show: boolean) => !show)}
              style={{ padding: '0px', userSelect: 'none' }}
              className={`hamburger hamburger--collapse ${
                sidebar ? 'is-active' : ''
              }`}
              type='button'>
              <span className='hamburger-box'>
                <span className='hamburger-inner'></span>
              </span>
            </button>
          </div>
        </div>
      </Container>
    </nav>
  );
}

export default Navbar;
