import usePageContent from '../../hooks/usePageContent';
import Container from '../../layout/Container/Container';
import styles from './PrivacyPolicy.module.scss';

function PrivacyPage() {
  const content = usePageContent();
  let privacyPolicy;

  if (content) {
    privacyPolicy = content.privacy_policy;
  }

  return (
    <Container>
      <div className={styles.root}>
        <h2>Polityka prywatności</h2>
        <p>{privacyPolicy}</p>
      </div>
    </Container>
  );
}

export default PrivacyPage;
