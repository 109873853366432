import Container from '../../layout/Container/Container';
import styles from './BlueBar.module.scss';

function BlueBar({
  header,
  subtitle,
  backgroundImg,
  variant = 1,
}: {
  header?: string;
  subtitle?: string;
  backgroundImg?: string;
  variant?: number;
}) {
  return (
    <div className={`${styles.root} ${styles[`variant${variant}`]}`}>
      <Container>
        {!!header && <h2>{header}</h2>}
        {!!subtitle && <p>{subtitle}</p>}
      </Container>
      {!!backgroundImg && <img loading='lazy' src={backgroundImg} alt='Tło' />}
    </div>
  );
}

export default BlueBar;
