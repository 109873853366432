import styles from './Arrows.module.scss';

export function Arrows({
  onBack = () => {},
  onNext = () => {},
  page,
}: {
  page?: number;
  onBack?: Function;
  onNext?: Function;
}) {
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <img
          decoding='async'
          loading='lazy'
          alt='Strzałka w lewo'
          onClick={() => onBack()}
          src='/assets/common/arrow.svg'></img>
        <p>|</p>
        <img
          decoding='async'
          loading='lazy'
          alt='Strzałka w prawo'
          onClick={() => onNext()}
          src='/assets/common/arrow.svg'></img>
      </div>
      {page && <p>{page}</p>}
    </div>
  );
}

export default Arrows;
