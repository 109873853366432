import styles from "./Features.module.scss";
import { useInView } from "react-intersection-observer";

function Features({ children }: { children?: any }) {
  const { inView, ref } = useInView({ triggerOnce: true });

  return (
    <div ref={ref} className={`${styles.root} ${inView ? styles.inView : ""}`}>
      {children}
    </div>
  );
}

export default Features;
