import styles from './CardsBox.module.scss';
import { TCard } from './Card';
import Card from './Card';
import Container from '../../layout/Container/Container';
import { useInView } from 'react-intersection-observer';

function CardsBox({
  cards = [],
  variant = 1,
}: {
  cards: TCard[];
  variant?: number;
}) {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <Container>
      <section
        ref={ref}
        className={`${styles.root} ${inView ? styles.inView : ''}`}>
        <ul>
          {cards.map((card, index) => (
            <Card variant={variant} key={index} {...card} />
          ))}
        </ul>
      </section>
    </Container>
  );
}

export default CardsBox;
