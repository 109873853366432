import { MainBanner } from '../../features/MainBanner/MainBanner';
import FeatureStrip from '../../features/FeatureStrip/FeatureStrip';
import Poster from '../../features/Poster/Poster';
import Copy from '../../layout/Copy/Copy';
import More from '../../layout/More/More';
import FeatureItem from '../../features/FeatureStrip/FeatureItem';
import Flex from '../../layout/Flex/Flex';
import CardsBox from '../../features/CardsBox/CardsBox';
import Button from '../../layout/Button/Button';
import BigNumber from '../../features/BigNumber/BigNumber';
import ReviewsBox from '../../features/ReviewsBox/ReviewsBox';
import NumbersBox from '../../layout/NumbersBox/NumbersBox';
import useWindowWidth from '../../hooks/useWindowWidth';
import useBanner from '../../hooks/useBanner';
import { TOffer } from '../../../store/offers';
import usePageContent from '../../hooks/usePageContent';
import { TReview } from '../../../store/reviews';
import { useState } from 'react';
import ReviewsViewer from '../../layout/ReviewsViewer/ReviewsViewer';
import { Helmet } from 'react-helmet';
import MetaData from '../../layout/MetaData/MetaData';

let poster = {
  title: 'MARCIN MOKRY',
  header:
    'Kompleksowa obsługa inwestycji budowlanych w zakresie budownictwa kubaturowego oraz przemysłowego',
  description:
    'Jako inspektor nadzoru z ponad 15-letnim doświadczeniem, specjalizującym się głównie w branży przemysłowej: produkcyjnej, logistycznej, deweloperskiej oraz zabytkowej na terenie południowej Polski, wspieram inwestorów w skutecznym zarzadzaniu projektami budowlanymi. Zapewniam stałą opiekę na każdym etapie projektu, od organizacji projektu/ koordynacji procesu projektowania, przez realizację projektu inwestycyjnego, do przygotowania dokumentacji zakończenia inwestycji.',
  backgroundImg: '/assets/home/background_1.jpeg',
  img: '/assets/home/marcin.jpg',
};

let poster2 = {
  title: 'OFERTY',
  header: 'Nadzoruję główne inwestycje przemysłowe i kubaturowe.',
  description: `Służę, doradzam, przeprowadzam audyty, a moim dodatkowym atutem jest
            wiedza z zakresu konstrukcji budowlanych`,
  subtitle:
    'Ofertę kieruje do inwestorów, deweloperów jak i generalnych wykonawców',
};

let numbers = {
  investors_count: '55',
  projects_count: '234',
  years: '20',
  header: 'Bezpieczeństwo i sukces Twojej inwestycji naszym priorytetem!',
};

const features2 = [
  {
    title: 'Terminowość',
    icon: '/assets/home/terminowosc.svg',
  },
  {
    title: 'Elastyczność',
    icon: '/assets/home/elastycznosc.svg',
  },
  {
    title: 'Zaufanie',
    icon: '/assets/home/zaufanie.svg',
  },
];

function HomePage({
  offers = [],
  reviews = [],
}: {
  offers: TOffer[];
  reviews: TReview[];
}) {
  let reviewsHeader;
  const { width } = useWindowWidth();
  const small = width < 768;
  const { banner } = useBanner();
  const content = usePageContent();
  const [currentReview, setCurrentReview] = useState<TReview | null>(null);

  let amountNumber, amountDescription;
  const features = offers
    .filter((offer) => offer.offer_title && offer.offer_icon)
    .map((offer) => ({ title: offer.offer_title, icon: offer.offer_icon }));

  if (content) {
    poster = {
      ...poster,
      title: content?.section_title,
      header: content?.section_header,
      description: content?.section_description,
      img: content?.section_img,
    };

    poster2 = {
      ...poster2,
      title: content?.offer_title,
      header: content?.offer_header,
      description: content?.offer_description,
      subtitle: content?.offer_subtitle,
    };

    numbers = {
      ...numbers,
      investors_count: content?.investors_count,
      projects_count: content?.projects_count,
      years: content?.years,
      header: content?.numbers_header,
    };

    amountNumber = content?.projects_count.split(/[a-zA-Z]+/)[0];
    amountDescription = content?.projects_count.replace(amountNumber, '');
    reviewsHeader = content?.reviews_header;
  }

  return (
    <>
      <MetaData {...content} />
      <div>
        {banner && (
          <MainBanner
            img={content?.background}
            description={content?.description}
            headline={content?.headline}
            handwrite={'Marcin Mokry'}
          />
        )}
        <FeatureStrip features={features} />
        <Poster
          maskWhite
          backgroundImg={poster.backgroundImg}
          variant={2}
          style={{ margin: '0' }}>
          <Copy style={{ padding: !small ? '2rem 0' : '1rem 0' }}>
            <h4>{poster.title}</h4>
            <h3>{poster.header}</h3>
            <p>{poster.description}</p>
            <More to={'/o-mnie'}>Czytaj więcej</More>
          </Copy>
          <img
            alt={poster.header}
            loading='lazy'
            style={{ objectFit: 'contain' }}
            src={poster.img}
          />
        </Poster>
        <Poster
          backgroundHeight='0%'
          style={{ paddingTop: !small ? '4rem' : '2rem', margin: '0' }}>
          <Copy>
            <h4>{poster2.title}</h4>
            <h3>{poster2.header}</h3>
            <p style={{ fontWeight: '500' }}>{poster2.subtitle}</p>
            <More to={`oferta/${offers[0]?.offer_href}`}>Czytaj więcej</More>
          </Copy>
          <Copy>
            <p style={{ margin: '1rem 0' }}>{poster2.description}</p>
            <Flex
              gap={small ? '1rem' : '0'}
              style={{
                height: '80px',
                marginBottom: width < 995 ? '1rem' : '',
              }}>
              {features2.map((feature, index) => (
                <FeatureItem key={index} {...feature} />
              ))}
            </Flex>
          </Copy>
        </Poster>
        <Poster
          style={{ paddingBottom: '4rem' }}
          column={true}
          backgroundImg='/assets/home/background_2.jpg'
          variant={3}
          backgroundHeight='90%'>
          <CardsBox
            variant={4}
            cards={offers
              .filter((o, index) => index < 4)
              .map((offer) => ({
                img: offer.offer_img,
                header: offer.offer_title,
                description:
                  (offer.offer_subtitle || offer.offer_description) +
                  (offer.offer_list
                    ? offer?.offer_list.replaceAll('*', ' ')
                    : ' '),
                href: `/oferta/${offer.offer_href}`,
              }))}
          />
          <Flex
            style={{ margin: small ? '3rem 0' : '6rem 0' }}
            justify='center'>
            <Button href={`/oferta/${offers[0]?.offer_href}`} variant={2}>
              Pozostałe usługi
            </Button>
          </Flex>
          <h2
            style={{
              color: '#003f85',
              maxWidth: '500px',
              fontWeight: '500',
              margin: '0 auto 4rem auto',
              textAlign: 'center',
            }}>
            {numbers.header}
          </h2>
          <NumbersBox>
            <BigNumber
              number={Number(numbers.years)}
              title={'Lat wspieramy inwestorów'}
            />
            <BigNumber
              currency={true}
              description={amountDescription}
              number={Number(amountNumber)}
              title={'Najdroższa inwestycja'}
            />
            <BigNumber
              number={Number(numbers.investors_count)}
              title={'Zadowolonych inwestorów'}
            />
          </NumbersBox>
        </Poster>
        <ReviewsBox
          setCurrentReview={setCurrentReview}
          header={reviewsHeader}
          reviews={reviews}
        />
        <ReviewsViewer
          reviews={reviews}
          currentReview={currentReview}
          close={() => setCurrentReview(null)}
        />
        <div style={{ margin: '5rem' }}></div>
      </div>
    </>
  );
}

export default HomePage;
