export type TReview = {
  title: string;
  description?: string;
  footer: string;
  img: string;
  date: string;
};

export const reviews = [
  {
    title: 'Rewelacyjna współpraca i dokładny nadzór nad inwestycją',
    img: '/assets/home/opinia1.jpg',
    footer: `Joanna Nowak
DSG Sp. z o.o.`,
  },
  {
    title: 'Rewelacyjna współpraca i dokładny nadzór nad inwestycją',
    img: '/assets/home/opinia2.jpg',
    footer: `Joanna Nowak
DSG Sp. z o.o.`,
  },
];
