import FeatureStrip from '../../features/FeatureStrip/FeatureStrip';
import { MainBanner } from '../../features/MainBanner/MainBanner';
import Poster from '../../features/Poster/Poster';
import CenterText from '../../layout/CenterText/CenterText';
import CardsBox from '../../features/CardsBox/CardsBox';
import Bullets from '../../features/Bullets/Bullets';
import Button from '../../layout/Button/Button';
import Copy from '../../layout/Copy/Copy';
import useWindowWidth from '../../hooks/useWindowWidth';
import useGoToReviews from '../../hooks/useGoToReviews';
import useBanner from '../../hooks/useBanner';
import usePageContent from '../../hooks/usePageContent';
import { TEMOffer } from '../../../store/emOffers';
import { TMarqueeItem } from '../../../store/marqueeEm';
import MetaData from '../../layout/MetaData/MetaData';

let poster = {
  title: 'EM GRUPA',
  header:
    'Pełnometrażowe działanie na budowie, kompleksowa ochrona interesów inwestora',
  description: `EM grupa to zespół doświadczonych ekspertów branżowych,
z którymi współpracuję od wielu lat, oferując dzięki temu kompleksową obsługę inwestycji budowlanych, zarówno w zakresie budownictwa kubaturowego, jak i przemysłowego.`,
  backgroundImg: '/assets/em-grupa/background.jpeg',
  img: '/assets/em-grupa/em_grupa.png',
};

let poster2 = {
  title: 'TWOJE KORZYŚCI',
  description: 'Dzięki jednemu zintegrowanemu zespołowi nadzoru:',
  list: `*Zapewniamy spójność i harmonijną współpracę między różnymi 
dziedzinami projektu, co minimalizuje ryzyko konfliktów 
i błędów.
*Skracamy czas realizacji projektu poprzez ciągłe monitorowanie 
postępów prac we wszystkich branżach oraz szybką identyfikację 
i rozwiązanie ewentualnych problemów.
*Zapewniamy wysoką jakości wykonania prac dzięki stosowaniu 
standardów w każdej dziedzinie.
*Optymalizujemy koszty dzięki racjonalnemu zarządzaniu 
projektowaniem, zakupem materiałów oraz eliminacji 
niepotrzebnych opóźnień i problemów.
*Zapewniamy bezpieczeństwo pracowników oraz użytkowników 
budynku poprzez skuteczne monitorowanie zgodności 
z przepisami bezpieczeństwa oraz kontrolę ryzyka związanego 
z realizacją projektu.`,
  backgroundImg: '/assets/home/background_2.jpg',
  img: '/assets/em-grupa/korzysci.jpeg',
};

let paragraphs = {
  paragraph1: `Pełnobranżowe działanie na budowie wymaga zintegrowanej współpracy
specjalistów z różnych dziedzin, aby zapewnić kompleksową ochronę
interesów inwestora oraz skuteczne i bezproblemowe wykonanie projektu
budowlanego.`,
  paragraph2: `Typowe dziedziny budownictwa, które wchodzą w zakres pełnobranżowego
działania na budowie, obejmują:`,
};

export function EMGrupaPage({
  marqueeEm = [],
  offers = [],
}: {
  offers: TEMOffer[];
  marqueeEm: TMarqueeItem[];
}) {
  const { width } = useWindowWidth();
  const { goToReviews } = useGoToReviews();
  const content = usePageContent();
  const { banner } = useBanner();

  const small = width < 1280;

  if (content) {
    poster = {
      ...poster,
      title: content.section_title,
      description: content.section_description,
      header: content.section_header,
      img: content.section_img,
    };

    poster2 = {
      ...poster2,
      title: content.advantage_title,
      description: content.advantage_subtitle,
      img: content.advantages_img,
      list: content.advantages_list,
    };

    paragraphs = {
      paragraph1: content.akapit_1,
      paragraph2: content.akapit_2,
    };
  }

  return (
    <>
      <MetaData {...content} />
      <div>
        {banner && (
          <MainBanner
            img={content?.background}
            description={content?.description}
            headline={content?.headline}
            logo='/assets/common/em-grupa.svg'
          />
        )}
        <FeatureStrip features={marqueeEm} />
        <Poster {...poster} maskWhite backgroundHeight={small ? '100%' : '80%'}>
          <Copy style={small ? { gap: '0rem' } : { gap: '1rem' }}>
            <h4>{poster.title}</h4>
            <h3 style={{ marginTop: small ? '1rem' : '0' }}>{poster.header}</h3>
            <p>{poster.description}</p>
          </Copy>
          <img
            loading='lazy'
            alt={poster.header}
            style={{
              height: '420px',
              objectFit: 'contain',
              position: small ? 'relative' : 'static',
              bottom: small ? '-50px' : '0',
            }}
            src={poster.img}
          />
        </Poster>
        <CenterText>
          <p style={{ marginTop: '2rem' }}>{paragraphs.paragraph1}</p>
          <p style={{ marginBottom: '2rem' }}>{paragraphs.paragraph2}</p>
        </CenterText>
        <CardsBox
          cards={offers.map((offer) => ({
            header: offer.em_offer_title,
            description: offer.em_offer_description,
            img: offer.img,
          }))}
        />
        <Poster
          variant={3}
          {...poster2}
          style={{ padding: width < 995 ? '2rem 0' : '4rem 0', gap: '2rem' }}>
          <Copy>
            <h4>{poster2.title}</h4>
            <p style={{ fontWeight: '500' }}>{poster2.description}</p>
            <Bullets bullets={poster2.list} />
            <div style={{ marginBottom: '1rem' }}>
              <Button onClick={goToReviews}>
                Przeczytaj opinie innych
                <img
                  alt='Strzałka'
                  loading='lazy'
                  src='/assets/common/arrow.svg'></img>
              </Button>
            </div>
          </Copy>
          <img
            alt={`Marcin Mokry - inspektor nadzoru`}
            loading='lazy'
            src={poster2.img}
          />
        </Poster>
      </div>
    </>
  );
}

export default EMGrupaPage;
