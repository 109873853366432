import { TProject } from '../components/pages/PortfolioPage/PortfolioPage';

export const projects: TProject[] = [
  {
    project_title: 'Piekarnia',
    project_img: '/assets/portfolio/piekarnia.jpg',
    project_images: [
      '/assets/portfolio/projects/project1.jpeg',
      '/assets/portfolio/projects/project2.jpg',
      '/assets/portfolio/projects/project3.jpeg',
      '/assets/portfolio/projects/project4.jpg',
      '/assets/portfolio/projects/project5.jpeg',
      '/assets/portfolio/projects/project6.jpg',
      '/assets/portfolio/projects/project7.jpeg',
      '/assets/portfolio/projects/project8.jpg',
      '/assets/portfolio/projects/project9.jpeg',
      '/assets/portfolio/projects/project10.jpeg',
    ],
    date: Date.now().toString(),
  },
  {
    project_title: 'InPost Bielsko-Biała',
    project_img: '/assets/portfolio/inpost.jpg',
    project_images: [
      '/assets/portfolio/projects/project2.jpg',
      '/assets/portfolio/projects/project1.jpeg',
      '/assets/portfolio/projects/project3.jpeg',
      '/assets/portfolio/projects/project4.jpg',
      '/assets/portfolio/projects/project5.jpeg',
      '/assets/portfolio/projects/project6.jpg',
      '/assets/portfolio/projects/project7.jpeg',
      '/assets/portfolio/projects/project8.jpg',
      '/assets/portfolio/projects/project9.jpeg',
      '/assets/portfolio/projects/project10.jpeg',
    ],
    date: Date.now().toString(),
  },
  {
    project_title: 'Zator',
    project_img: '/assets/portfolio/zator.jpg',
    project_images: [
      '/assets/portfolio/projects/project1.jpeg',
      '/assets/portfolio/projects/project2.jpg',
      '/assets/portfolio/projects/project3.jpeg',
      '/assets/portfolio/projects/project4.jpg',
      '/assets/portfolio/projects/project5.jpeg',
      '/assets/portfolio/projects/project6.jpg',
      '/assets/portfolio/projects/project7.jpeg',
      '/assets/portfolio/projects/project8.jpg',
      '/assets/portfolio/projects/project9.jpeg',
      '/assets/portfolio/projects/project10.jpeg',
    ],
    date: Date.now().toString(),
  },
  {
    project_title: 'Piekary',
    project_img: '/assets/portfolio/piekary.jpeg',
    project_images: [
      '/assets/portfolio/projects/project1.jpeg',
      '/assets/portfolio/projects/project2.jpg',
      '/assets/portfolio/projects/project3.jpeg',
      '/assets/portfolio/projects/project4.jpg',
      '/assets/portfolio/projects/project5.jpeg',
      '/assets/portfolio/projects/project6.jpg',
      '/assets/portfolio/projects/project7.jpeg',
      '/assets/portfolio/projects/project8.jpg',
      '/assets/portfolio/projects/project9.jpeg',
      '/assets/portfolio/projects/project10.jpeg',
    ],
    date: Date.now().toString(),
  },
  {
    project_title: 'Bieruń',
    project_img: '/assets/portfolio/bierun.jpeg',
    project_images: [
      '/assets/portfolio/projects/project1.jpeg',
      '/assets/portfolio/projects/project2.jpg',
      '/assets/portfolio/projects/project3.jpeg',
      '/assets/portfolio/projects/project4.jpg',
      '/assets/portfolio/projects/project5.jpeg',
      '/assets/portfolio/projects/project6.jpg',
      '/assets/portfolio/projects/project7.jpeg',
      '/assets/portfolio/projects/project8.jpg',
      '/assets/portfolio/projects/project9.jpeg',
      '/assets/portfolio/projects/project10.jpeg',
    ],
    date: Date.now().toString(),
  },
  {
    project_title: 'Sklep Delfin',
    project_img: '/assets/portfolio/delfin.jpg',
    project_images: [
      '/assets/portfolio/projects/project1.jpeg',
      '/assets/portfolio/projects/project2.jpg',
      '/assets/portfolio/projects/project3.jpeg',
      '/assets/portfolio/projects/project4.jpg',
      '/assets/portfolio/projects/project5.jpeg',
      '/assets/portfolio/projects/project6.jpg',
      '/assets/portfolio/projects/project7.jpeg',
      '/assets/portfolio/projects/project8.jpg',
      '/assets/portfolio/projects/project9.jpeg',
      '/assets/portfolio/projects/project10.jpeg',
    ],
    date: Date.now().toString(),
  },
];
