import { useState } from 'react';
import useWindowWidth from '../../hooks/useWindowWidth';
import Flex from '../../layout/Flex/Flex';
import styles from './ContactForm.module.scss';
import { API } from '../../../API/api';

function ContactForm() {
  const [isSent, setIsSent] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
    code: '',
  });

  const { width } = useWindowWidth();
  const small = width < 768;

  const handleChange = (name: string) => {
    return (e: any) => {
      setForm((form) => {
        form = { ...form, [name]: e.target.value };
        return form;
      });
    };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await API.sendMessage(form);
      setIsSent(true);
    } catch {}
  };

  const checkValidation = () => {
    if (form.name.length < 1) return;
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) return;
    if (form.message.length < 10) return;
    return true;
  };

  const Thanks = () => (
    <div className={styles.thanks}>
      <h3>Dziękujemy za kontakt!</h3>
      <div className={styles.formDetails}>
        <p>Imię i nazwisko:</p>
        <p>
          <b>{form.name}</b>
        </p>
        <p>E-mail:</p>
        <p>
          <b>{form.email}</b>
        </p>
        <p>Wiadomość:</p>
        <p>{form.message}</p>
      </div>
    </div>
  );

  const isValid = checkValidation();

  return (
    <div className={styles.root}>
      {isSent ? (
        <Thanks />
      ) : (
        <>
          <h3>FORMULARZ KONTAKTOWY</h3>
          <form onSubmit={handleSubmit}>
            <Flex gap='1rem' column={small}>
              <input
                onChange={handleChange('name')}
                defaultValue={form.name}
                placeholder='Imię i Nazwisko'
              />
              <input
                onChange={handleChange('email')}
                defaultValue={form.email}
                placeholder='E-mail'
              />
            </Flex>
            <textarea
              onChange={handleChange('message')}
              defaultValue={form.message}
              placeholder='Wiadomość...'
            />
            <Flex>
              <div></div>
            </Flex>
            <button className={!isValid ? styles.disabled : ''} type='submit'>
              WYŚLIJ
            </button>
          </form>
        </>
      )}
    </div>
  );
}

/*

<input
                onChange={handleChange('code')}
                defaultValue={form.code}
                placeholder='Kod potwierdzający'
              />

*/

export default ContactForm;
