import { useNavigate } from 'react-router-dom';

function useGoToReviews() {
  const navigate = useNavigate();

  const goToReviews = () => {
    navigate('/');
    setTimeout(() => {
      const reviews = document.querySelector('.reviews-wrapper');
      if (reviews) {
        reviews.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
  };

  return { goToReviews };
}

export default useGoToReviews;
