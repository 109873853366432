import { useState } from 'react';

function useImageViewer() {
  const [images, setImages] = useState<string[]>([]);
  const [description, setDescription] = useState<string | undefined>('');

  return {
    images: !images.length
      ? []
      : images.map((image: any) => image.full_image_url),
    description,
    show: (images: string[] = [], description?: string) => {
      setImages(images);
      setDescription(description);
    },
    close: () => setImages([]),
  };
}

export default useImageViewer;
