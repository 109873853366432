import Copy from '../../layout/Copy/Copy';
import Poster from '../../features/Poster/Poster';
import Bullets from '../../features/Bullets/Bullets';
import Button from '../../layout/Button/Button';
import List from '../../layout/List/List';
import { useNavigate, useParams } from 'react-router-dom';
import { TOffer } from '../../../store/offers';
import Grid from '../../layout/Grid/Grid';
import Container from '../../layout/Container/Container';
import Pointer from '../../features/Pointer/Pointer';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useEffect } from 'react';
import BlueBar from '../../features/BlueBar/BlueBar';
import useGoToReviews from '../../hooks/useGoToReviews';
import usePageContent from '../../hooks/usePageContent';
import MetaData from '../../layout/MetaData/MetaData';

let poster = {
  title: 'TWOJE KORZYŚĆI',
  description:
    'Korzystając z usług Inspektora Nadzoru - Marcina Mokrego, zapewniasz bezpieczeństwo swojej inwestycji budowlanej. Postaw na:',
  img: '/assets/em-grupa/korzysci.jpeg',
  points: `*Specjalistyczną wiedzę w zakresie inwestycji przemysłowych 
i kubaturowych.
*Wieloletnie doświadczenie, które pozwala przewidzieć 
i wyeliminować kosztowne błędy (nadużycia finansowe)/ ryzyka podczas planowania i realizacji inwestycji.
*Sprawdzone rozwiązania dla Twojej branży.
*Umiejętne zarządzanie zmianą w trakcie realizacji projektu.
*Właściwe zarządzanie czasem, tj. monitorowanie postępu prac.
*Zapewnienie terminowego ukończenia inwestycji.
*Bezpieczeństwo i jakość Twojej inwestycji.
*Spokojną głowę jeśli chodzi o nadzór inwestycji.`,
};

function OfferPage({ offers = [] }: { offers: TOffer[] }) {
  const { offerName } = useParams();
  const navigate = useNavigate();
  const { width } = useWindowWidth();
  const { goToReviews } = useGoToReviews();
  const content = usePageContent();

  let featuresColumns = 3;
  width < 995 && (featuresColumns -= 1);
  width < 768 && (featuresColumns -= 1);

  const small = width < 768;

  const currentOffer = offers.find((offer) => offer?.offer_href === offerName);

  useEffect(() => {
    if (!currentOffer && offers.length) {
      navigate(`/oferta/${offers[0].offer_href}`);
    }
  }, [currentOffer, offers]);

  if (!currentOffer) return <></>;

  const { offer_title } = currentOffer;

  if (content) {
    poster = {
      ...poster,
      title: content.advantage_title,
      description: content.advantage_subtitle,
      img: content.advantages_img,
      points: content.advantages_list,
    };
  }

  return (
    <>
      <MetaData {...content} />
      <div>
        <BlueBar
          key={offer_title}
          header={offer_title}
          variant={2}
          backgroundImg='/assets/portfolio/background.webp'
        />
        <Poster
          style={{ marginTop: '1rem' }}
          key={currentOffer.offer_href}
          backgroundHeight='0px'>
          <Copy style={{ padding: '1rem 0' }}>
            <h4>MARCIN MOKRY</h4>
            <h3 style={{ fontSize: '26px' }}>{offer_title}</h3>
            {!!currentOffer.offer_subtitle && (
              <p
                style={{
                  marginBottom: '2rem',
                  whiteSpace: 'break-spaces',
                  fontWeight: '500',
                }}>
                {currentOffer.offer_subtitle}
              </p>
            )}
            {!!currentOffer?.offer_description && (
              <p
                style={{
                  marginBottom: '2rem',
                  whiteSpace: 'break-spaces',
                }}>
                {currentOffer?.offer_description}
              </p>
            )}
            {!!currentOffer.offer_list && (
              <List gap='1rem'>
                {currentOffer.offer_list
                  .split('*')
                  .filter((offer) => offer)
                  .map((point, index) => (
                    <Pointer
                      key={index}
                      description={point.replace('\n', ' ')}
                    />
                  ))}
              </List>
            )}
          </Copy>
          <img
            className={'top-image'}
            alt={currentOffer.offer_title}
            loading='lazy'
            style={{
              height: '600px',
              margin: 'auto 0',
            }}
            src={currentOffer.offer_img}
          />
        </Poster>
        <Container>
          <p
            style={{
              color: 'rgba(0,0,0, 0.4)',
              textAlign: 'center',
              padding: '2rem 0',
              fontWeight: '500',
            }}>
            POZOSTAŁE USŁUGI
          </p>
          <Grid
            cols={featuresColumns}
            gap='1rem'
            style={{ marginBottom: '2rem' }}>
            {offers
              .filter((offer) => offer !== currentOffer)
              .map((offer) => (
                <Button
                  key={offer.offer_href}
                  onClick={() => navigate(`/oferta/${offer.offer_href}`)}
                  style={{ height: '4rem' }}
                  variant={4}>
                  {offer.offer_title}
                </Button>
              ))}
          </Grid>
        </Container>
        <Poster
          backgroundImg='/assets/home/background_2.jpg'
          variant={3}
          {...poster}
          style={{ padding: '4rem 0', gap: '2rem' }}>
          <Copy>
            <h4>{poster.title}</h4>
            <p style={{ fontWeight: '500' }}>{poster.description}</p>
            <Bullets bullets={poster.points} />
            <div style={{ marginBottom: '1rem' }}></div>
            <h5
              style={{ fontSize: '16px', fontWeight: '500', color: '#003f85' }}>
              Oddając projekt inwestycyjny w moje ręce - możesz skupić się na
              biznesie. Prace zostaną wykonane uczciwie, rzetelnie, z pełnym
              zaangażowaniem.
            </h5>
            <div style={{ marginBottom: '2rem' }}></div>
          </Copy>
          <div>
            <img
              alt={`Marcin Mokry - inspektor nadzoru`}
              loading='lazy'
              style={{ height: !small ? '500px' : '300px', width: '100%' }}
              src={poster.img}
            />
            <Button
              style={{ margin: '4rem auto 0 auto' }}
              onClick={goToReviews}>
              Przeczytaj opinie innych
              <img
                alt='Strzałka'
                loading='lazy'
                src='/assets/common/arrow.svg'></img>
            </Button>
          </div>
        </Poster>
      </div>
    </>
  );
}

export default OfferPage;
