import { useInView } from "react-intersection-observer";
import Container from "../../layout/Container/Container";
import styles from "./MainBanner.module.scss";
import { useState } from "react";

export type Banner = {
  href?: string;
  img: string;
  headline: string;
  description: string;
  handwrite?: string;
  logo?: string;
};

export function MainBanner({
  img,
  headline,
  description,
  logo,
  handwrite,
}: Banner) {
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <div
      ref={ref}
      className={`${styles.root} ${inView ? styles.inView : ""} ${
        loaded ? styles.loaded : ""
      }`}>
      <img
        decoding="async"
        loading="lazy"
        onLoad={() => setLoaded(true)}
        alt="Tło"
        className={`${styles.banner} ${loaded ? styles.loaded : ""}`}
        src={img}
      />
      <div className={styles.overlay}>
        {loaded && (
          <Container>
            <div className={styles.container}>
              <h1>{headline}</h1>
              <p>"{description}"</p>
              {logo && (
                <img
                  alt={headline}
                  loading="lazy"
                  className={"shadow"}
                  src={logo}
                />
              )}
              {handwrite && (
                <p style={{ textAlign: "end" }} className={"hand"}>
                  {handwrite}
                </p>
              )}
            </div>
          </Container>
        )}
      </div>
    </div>
  );
}
