import { useNavigate } from 'react-router-dom';
import Container from '../Container/Container';
import styles from './Footer.module.scss';

function Footer({ contact }: { contact: any }) {
  const navigate = useNavigate();
  if (!contact) return <></>;
  return (
    <footer className={styles.root}>
      <img src={'/assets/common/footer.svg'} className={styles.background} />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.block}>
            <div>
              <img
                decoding='async'
                loading='lazy'
                className={styles.brand}
                alt={'Logo Marcin Mokry'}
                src={'/assets/common/marcin-mokry.svg'}
              />
              <p>{contact.name}</p>
            </div>
            <p>{contact.description}</p>
            <div style={{ marginTop: '1rem' }}>
              <a href={contact.fb}>
                <img
                  loading='lazy'
                  className={styles.brand}
                  alt={'Facebook'}
                  src={'/assets/common/fb.svg'}
                />
              </a>
              <a href={contact.ln}>
                <img
                  loading='lazy'
                  alt='LinkedIn'
                  className={styles.brand}
                  src={'/assets/common/in.svg'}
                />
              </a>
            </div>
          </div>

          <div className={styles.block}>
            <div>
              <img
                loading='lazy'
                className={styles.brand}
                alt='Telefon'
                src={'/assets/common/phone2.svg'}
              />
              <p>{contact.phone}</p>
            </div>
            <div>
              <p className={styles.email}>@</p>
              <p>{contact.email}</p>
            </div>

            <div>
              <a style={{ color: 'white' }} href='/kontakt'>
                Formularz kontaktowy
              </a>
            </div>
          </div>

          <div className={styles.flex}>
            <object
              style={{ height: '3.5rem' }}
              type='image/svg+xml'
              data='/assets/common/em-grupa.svg'></object>
            <div className={styles.block}>
              <h5 style={{ fontWeight: '500' }}>{contact.company}</h5>
              <p>{contact.address}</p>
              <div>
                <p className={styles.wide}>NIP: {contact.nip} </p>
                <p className={styles.wide}>KRS: {contact.krs}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.strip}>
        <Container>
          <div className={styles.wrapper}>
            <p
              onClick={() => navigate('/polityka-prywatnosci')}
              style={{ margin: '0', cursor: 'pointer' }}>
              Polityka prywatności
            </p>
            <p>
              ©{new Date(Date.now()).getFullYear()} {contact.company}
            </p>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
