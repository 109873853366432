export type TMarqueeItem = {
  title: string;
  icon: string;
};

export const marqueeEm = [
  {
    title: 'Pełnobranżowy nadzór inwestorski',
    icon: '/assets/em-grupa/pelnobranzowy.svg',
  },
  {
    title: 'Projektowanie',
    icon: '/assets/em-grupa/projektowanie.svg',
  },
  {
    title: 'Analiza dokumentacji projektowej',
    icon: '/assets/em-grupa/analiza.svg',
  },
  {
    title: 'Nadzór przy obiektach zabytkowych',
    icon: '/assets/em-grupa/nadzor.svg',
  },
];
