import More from "../../layout/More/More";
import styles from "./Card.module.scss";

export type TCard = {
  img: string;
  header: string;
  description?: string;
  author?: string;
  variant?: number;
  href?: string;
  onClick?: Function;
};

function Card({
  img,
  header,
  author,
  description,
  onClick = () => {},
  variant = 1,
  href,
}: TCard) {
  return (
    <li
      onClick={() => onClick()}
      className={`${styles.root} ${styles[`variant${variant}`]}`}>
      <img decoding="async" loading="lazy" src={img} alt={header} />
      <div className={styles.copy}>
        <h5>{header}</h5>
        <p>
          {description ||
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste nostrum aut sapiente laudantium quis vitae ut, facilis dolor ad voluptatem praesentium rem illum sunt. Ex quo eius asperiores alias ipsa!"}
        </p>
        {author ? <p className={styles.author}>{author}</p> : ""}
        {variant === 2 ? <p className={styles.plus}>+</p> : ""}
        {variant === 4 && href ? (
          <div className={styles.plus}>
            <More to={href}>CZYTAJ WIĘCEJ</More>
          </div>
        ) : (
          ""
        )}
      </div>
    </li>
  );
}

export default Card;
