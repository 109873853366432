import './styles.scss';
import Navbar from './components/layout/Navbar/Navbar';
import { Route, Routes, useLocation } from 'react-router-dom';
import EMGrupaPage from './components/pages/EMGrupaPage/EMGrupaPage';
import Footer from './components/layout/Footer/Footer';
import HomePage from './components/pages/HomePage/HomePage';
import AboutPage from './components/pages/AboutPage/AboutPage';
import OfferPage from './components/pages/OfferPage/OfferPage';
import PortfolioPage from './components/pages/PortfolioPage/PortfolioPage';
import ContactPage from './components/pages/ContactPage/ContactPage';
import NotFound from './components/pages/NotFound/NotFound';
import { Sidebar } from './components/layout/Sidebar/Sidebar';
import { useState, useEffect } from 'react';
import useWindowWidth from './components/hooks/useWindowWidth';
import BackLines from './components/layout/BackLines/BackLines';
import useContent from './components/hooks/useContent';
import PrivacyPage from './components/pages/PrivacyPage/PrivacyPage';

function App() {
  const [sidebar, setSidebar] = useState(false);
  const { width } = useWindowWidth();
  const { content } = useContent();
  const { pathname } = useLocation();

  useEffect(() => {
    if (sidebar) {
      setSidebar(false);
    }
  }, [width]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (sidebar) {
      setSidebar(false);
    }
  }, [pathname]);

  return (
    <>
      <Navbar
        contact={content.contact}
        offers={content.offers}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <div className='App'>
        <BackLines />
        {width <= 995 && <Sidebar offers={content.offers} show={sidebar} />}
        <Routes>
          <Route
            path='/'
            element={
              <HomePage reviews={content.reviews} offers={content.offers} />
            }
          />
          <Route
            path='/em-grupa'
            element={
              <EMGrupaPage
                marqueeEm={content.marqueeEm}
                offers={content.emOffers}
              />
            }
          />
          <Route path='/o-mnie' element={<AboutPage />} />
          <Route
            path='/realizacje'
            element={<PortfolioPage projects={content.projects} />}
          />
          <Route path='/kontakt' element={<ContactPage />} />
          <Route path='/polityka-prywatnosci' element={<PrivacyPage />} />
          <Route
            path='/oferta/*'
            element={<OfferPage offers={content.offers} />}
          />
          <Route
            path='/oferta/:offerName'
            element={<OfferPage offers={content.offers} />}
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer contact={content.contact} />
      </div>
    </>
  );
}

export default App;
