import styles from './Grid.module.scss';

export type TGrid = {
  cols?: number;
  rows?: number;
  gap?: string;
  style?: any;
};

function Grid({
  children,
  cols,
  rows,
  gap,
  style = {},
}: TGrid & { children?: any }) {
  const localStyle: any = {};

  cols && (localStyle.gridTemplateColumns = `1fr `.repeat(cols));
  rows && (localStyle.gridTemplateRows = `1fr `.repeat(rows));
  gap && (localStyle.gap = gap);

  return (
    <div style={{ ...localStyle, ...style }} className={styles.root}>
      {children}
    </div>
  );
}

export default Grid;
