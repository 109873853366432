import { useNavigate } from 'react-router-dom';
import styles from './Button.module.scss';

function Button({
  children,
  variant = 1,
  style = {},
  onClick = () => {},
  href,
}: {
  onClick?: Function;
  children?: any;
  style?: any;
  href?: string;
  variant?: number;
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (href) {
      navigate(href);
      window.scrollTo({ top: 0 });
    }
    onClick();
  };

  return (
    <button
      onClick={handleClick}
      style={style}
      className={`${styles.root} ${styles?.[`variant${variant}`]}`}>
      {children}
      {variant === 2 && (
        <img
          alt={'Strzałka'}
          loading='lazy'
          src='/assets/common/arrow.svg'></img>
      )}
    </button>
  );
}

export default Button;
