import Flex from '../../layout/Flex/Flex';
import styles from './Feature.module.scss';

export type TFeature = {
  img?: string;
  icon?: string;
  title?: string;
  description?: string;
  subtitle?: string;
  variant?: number;
};

function Feature({
  img,
  title,
  subtitle,
  description,
  icon,
  variant = 1,
}: TFeature) {
  return (
    <div
      style={{ width: '100%' }}
      className={`${styles.root} ${styles[`variant${variant}`]}`}>
      <Flex style={{ width: '100%' }} gap='0.5rem' column align='center'>
        <img alt={title} decoding='async' loading='lazy' src={img || icon} />
        <h4>{title}</h4>
        <p>{subtitle || description}</p>
      </Flex>
    </div>
  );
}

export default Feature;
