import Feature from '../../features/Features/Feature';
import Poster from '../../features/Poster/Poster';
import Button from '../../layout/Button/Button';
import Container from '../../layout/Container/Container';
import Copy from '../../layout/Copy/Copy';
import Flex from '../../layout/Flex/Flex';
import Features from '../../features/Features/Features';
import CenterText from '../../layout/CenterText/CenterText';
import Pointer from '../../features/Pointer/Pointer';
import List from '../../layout/List/List';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useNavigate } from 'react-router-dom';
import usePageContent from '../../hooks/usePageContent';
import MetaData from '../../layout/MetaData/MetaData';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

let poster = {
  title: 'MARCIN MOKRY',
  header: `Kompleksowa obsługa inwestycji budowlanych w zakresie budownictwa kubaturowego 
oraz przemysłowego`,
  description: `Jako inspektor nadzoru z ponad 15-letnim doświadczeniem, specjalizującym się głównie w branży przemysłowej: produkcyjnej, logistycznej, deweloperskiej oraz zabytkowej na terenie południowej Polski, wspieram inwestorów w skutecznym zarzadzaniu projektami budowlanymi. Zapewniam stałą opiekę na każdym etapie projektu, 
od organizacji projektu/ koordynacji procesu projektowania, przez realizację projektu inwestycyjnego, do przygotowania dokumentacji zakończenia inwestycji. Nadzoruję głównie inwestycje przemysłowe
i kubaturowe. Służę doradztwem, przeprowadzam audyty, a moim dodatkowym atutem jest wiedza z zakresu konstrukcji budowlanych.`,
  img: '/assets/home/marcin.jpg',
};

let poster2 = {
  title: 'MARCIN MOKRY',
  header:
    'Jako doświadczony inspoektor nadzoru, szczególną uwagę przykładam do kluczowych kwestii wpływających na sukces projektu, tj.:',
  img: '/assets/about/dol.jpg',
  advantages_list: `
    *Zgodność inwestycji z obowiązującymi normami!! budowlanymi, lokalnymi przepisami oraz standardami jakości.
    *Kontrola jakośći!! prac budowalnych, używanych materiałów 
i zgodności z projektem.
    *Zarządzanie harmonogramem!! Skutecznie i sprawnie koordynuje pracę różnych podwykonawców, monitoruje postępy prac 
i zapewnia, że projekt utrzymuje się zgodnie z harmonogramem. Podejście proaktywne w identyfikowaniu potencjalnych opóźnień jest kluczowe.
*Kontrola kosztów!! W celu zachowania rentowności projektu 
i uniknięcia przekroczeń budżetowych skutecznie kontroluje koszty 
i nadzoruję budżet inwestycji.
*Bezpieczeństwo na budowie!! Zapewnienie przestrzegania wszelkich 
przepisów dotyczących bezpieczeństwa  pracowników, gości i innych 
osób związanych z projektem na budowie jest niezwykle ważne.
*Komunikacja i raportowanie!! Regularna i przejrzysta komunikacja między nadzorem budowlanym a inwestorem jest kluczowa. Inwestor powinien być na bieżąco informowany o postępach, problemach 
i decyzjach związanych z projektem.
*Rozwiązywanie problemów!! Nadzór budowlany powinien być gotów do szybkiego rozwiązywania problemów, które mogą pojawić się w trakcie budowy. Umiejętność szybkiego reagowania i podejmowania skutecznych decyzji jest kluczowa.
  `,
};

let features = [
  {
    img: 'assets/about/icons/wysoka.svg',
    title: 'Wysoka jakość nadzoru',
    description: 'Wysoka jakość i zgodność z regulacjami prawnymi',
  },
  {
    img: 'assets/about/icons/terminowosc.svg',
    title: 'Terminowość',
    description: 'Wysoka jakość i zgodność z regulacjami prawnymi',
  },
  {
    img: 'assets/about/icons/specjalistyczna.svg',
    title: 'Specjalistyczna wiedza',
    description: 'Wysoka jakość i zgodność z regulacjami prawnymi',
  },
  {
    img: 'assets/about/icons/elastycznosc.svg',
    title: 'Elastyczność',
    description: 'Wysoka jakość i zgodność z regulacjami prawnymi',
  },
  {
    img: 'assets/about/icons/dlugoterminowe.svg',
    title: 'Długoterminowe partnerstwo',
    description: 'Wysoka jakość i zgodność z regulacjami prawnymi',
  },
  {
    img: 'assets/about/icons/efektywnosc.svg',
    title: 'Efektywność kosztowa',
    description: 'Wysoka jakość i zgodność z regulacjami prawnymi',
  },
];

let paragraph = `"Moje pełne zaangażowanie w proces nadzoru, a także wieloletnie
doświadczenie na placu budowy (znajomość rozwiązań i
przeciwdziałanie błędom) sprawia, że jestem właściwą inwestycją w
bezpieczną budowę."`;

let bullets = [
  {
    bold: 'Zgodność inwestycji z obowiązującymi normami',
    description: `budowlanymi, lokalnymi przepisami oraz standardami jakości.`,
  },
  {
    bold: 'Kontrola jakośći',
    description: `prac budowalnych, używanych materiałów 
i zgodności z projektem.`,
  },
  {
    bold: 'Zarządzanie harmonogramem',
    description: `Skutecznie i sprawnie koordynuje pracę różnych podwykonawców, monitoruje postępy prac 
i zapewnia, że projekt utrzymuje się zgodnie z harmonogramem. Podejście proaktywne w identyfikowaniu potencjalnych opóźnień jest kluczowe.`,
  },
  {
    bold: 'Kontrola kosztów',
    description: `W celu zachowania rentowności projektu 
i uniknięcia przekroczeń budżetowych skutecznie kontroluje koszty 
i nadzoruję budżet inwestycji.`,
  },
  {
    bold: 'Bezpieczeństwo na budowie',
    description: `Zapewnienie przestrzegania wszelkich 
przepisów dotyczących bezpieczeństwa  pracowników, gości i innych 
osób związanych z projektem na budowie jest niezwykle ważne.`,
  },
  {
    bold: 'Komunikacja i raportowanie',
    description: `Regularna i przejrzysta komunikacja między nadzorem budowlanym a inwestorem jest kluczowa. Inwestor powinien być na bieżąco informowany o postępach, problemach 
i decyzjach związanych z projektem.`,
  },
  {
    bold: 'Rozwiązywanie problemów',
    description: `Nadzór budowlany powinien być gotów do szybkiego rozwiązywania problemów, które mogą pojawić się w trakcie budowy. Umiejętność szybkiego reagowania i podejmowania skutecznych decyzji jest kluczowa.`,
  },
];

function AboutPage() {
  const { width } = useWindowWidth();
  const small = width < 995;
  const navigate = useNavigate();
  const content = usePageContent();
  let youTube, youTubeDescription;

  if (content) {
    const advantages = new Array(6)
      .fill(1)
      .map((v, index) => content[`advantage_${index + 1}`]);
    features = advantages;
    paragraph = content.paragraph;

    if (content.advantages_list) {
      bullets = content.advantages_list
        .split('*')
        .filter((b: string) => b)
        .map((adv: string) => {
          const [bold, description] = adv.split('!!');
          return { bold, description };
        });
    }

    poster = {
      ...poster,
      title: content.section_title,
      header: content.section_header,
      img: content.section_img,
      description: content.section_description,
    };

    poster2 = {
      ...poster2,
      title: content.advantage_title,
      header: content.advantage_subtitle,
      img: content.advantages_img,
    };

    if (content.youtube_link && content.youtube_description) {
      youTube = content.youtube_link.split('v=')[1];
      youTubeDescription = content.youtube_description;
    }
  }

  return (
    <>
      <MetaData {...content} />
      <Poster
        maskWhite
        backgroundImg='/assets/about/background.jpeg'
        variant={2}
        style={{ margin: '0', padding: '0' }}>
        <Copy style={{ padding: !small ? '3rem 0' : '1rem 0' }}>
          <h4>{poster.title}</h4>
          <h3>{poster.header}</h3>
          <p>{poster.description}</p>
        </Copy>
        <img
          alt={poster.title}
          loading='lazy'
          style={{ objectFit: 'contain' }}
          src={poster.img}
        />
      </Poster>
      <Poster
        backgroundImg='/assets/about/background_2.jpg'
        column
        style={{ marginTop: '2rem', padding: '0' }}
        backgroundHeight='50%'>
        <Features>
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </Features>
        <CenterText>
          <div
            style={{
              margin: !small ? '2rem 4rem' : '2rem',
              fontWeight: '500',
            }}>
            {paragraph}
          </div>
        </CenterText>
      </Poster>
      {youTube && youTubeDescription && (
        <div
          style={{
            maxWidth: '700px',
            margin: 'auto',
            paddingTop: '4rem',
            marginBottom: '2rem',
          }}>
          <LiteYouTubeEmbed id={youTube} title={youTubeDescription} />
        </div>
      )}
      <Poster backgroundHeight='0px' style={{ padding: '2rem 0 5rem 0' }}>
        <img
          loading='lazy'
          className={'top-image'}
          style={{ height: '600px', margin: 'auto 0' }}
          src={poster2.img}
          alt={poster2.title}
        />
        <Copy style={{ marginTop: small ? '1rem' : '0' }}>
          <h4>{poster2.title}</h4>
          <h3 style={{ fontSize: '26px' }}>{poster2.header}</h3>
          <List gap='1rem'>
            {bullets.map((pointer, index) => (
              <Pointer key={index} {...pointer} />
            ))}
          </List>
        </Copy>
      </Poster>
      <Container>
        <Flex style={{ padding: '0 0 5rem 0' }} justify='center'>
          <Button
            onClick={() => navigate('/kontakt')}
            style={{ padding: !small ? '1rem 6rem' : '1rem 3rem', gap: '1rem' }}
            variant={3}>
            POROZMAWIAJMY!
            <img
              alt='Porozmawiajmy'
              loading='lazy'
              src='assets/about/icons/porozmawiajmy.svg'
            />
          </Button>
        </Flex>
      </Container>
    </>
  );
}

export default AboutPage;
