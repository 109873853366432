import styles from './Bullets.module.scss';

function Bullets({ bullets = '' }: { bullets: string }) {
  return (
    <ul className={styles.root}>
      {bullets
        .split('*')
        .filter((b) => b)
        .map((bullet, index) => (
          <li key={index}>{bullet}</li>
        ))}
    </ul>
  );
}

export default Bullets;
