import styles from './Pointer.module.scss';

function Pointer({
  bold,
  description,
}: {
  bold?: string;
  description?: string;
}) {
  return (
    <li className={styles.root}>
      {bold ? <b>{bold}:</b> : ''}
      {description ? (
        <p>{description}</p>
      ) : (
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur, mollitia! Ipsam sequi repellat suscipit rerum, quaerat sunt delectus!'
      )}
    </li>
  );
}

export default Pointer;
