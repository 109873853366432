import styles from "./Container.module.scss";

export function Container({
  children,
  variant = 1,
}: {
  variant?: number;
  children: any;
}) {
  return (
    <div className={`${styles.root} ${styles[`variant${variant}`]}`}>
      {children}
    </div>
  );
}

export default Container;
