import styles from './CenterText.module.scss';
import Container from '../Container/Container';

function CenterText({ children }: { children: any }) {
  return (
    <Container>
      <section className={styles.root}>{children}</section>
    </Container>
  );
}

export default CenterText;
