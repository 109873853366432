import styles from './List.module.scss';

function List({ children, gap }: { children?: any; gap?: string }) {
  return (
    <ul className={styles.root} style={gap ? { gap } : {}}>
      {children}
    </ul>
  );
}

export default List;
